import { combineReducers } from 'redux';
// import currentUserReducer from './currentUserReducer.js';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
// Add firebase to reducers
export default combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  // currentUser: currentUserReducer
});
