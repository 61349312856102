import { Button, Dropdown, Icon, Menu, Tag, Typography } from 'antd';
import { compose, lifecycle } from 'recompose';
import { first, isEmpty, startCase, values } from 'lodash';

import InvitesButton from './components/InvitesButton';
import Loader from '../../shared/components/Loader';
import React from 'react';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

const Profile = (props) => {
  const { Text } = Typography;
  const { profile, articles, auth, } = props;
  if (!profile.isLoaded || !articles.isLoaded) {
    return (
      <Loader />
    );
  }
  if (profile.isEmpty) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        Sorry, this profile does not exist.
      </div>
    );
  }

  const menu = (
    <Menu>
      <Menu.Item style={{ fontSize: '12px' }}>
        <a target="_blank" rel="noopener noreferrer" href="mailto:ciao@title.cx">
          Send Feedback
        </a>
      </Menu.Item>
      <Menu.Item style={{ fontSize: '12px' }}>
        <a target="_blank" rel="noopener noreferrer" href="mailto:support@title.cx">
          Contact Support
        </a>
      </Menu.Item>
      <Menu.Item style={{ fontSize: '12px' }}>
        <a href="/logout">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const isCurrentUserAuthor = auth && auth.uid == profile.id;
  const visibleArticles = isCurrentUserAuthor && articles.all ? articles.all : articles.all.filter(a => a.status === 'PUBLIC');
  return (
    <div>
      {
        isCurrentUserAuthor &&
        <div>
          <div style={{ position: 'fixed', display: 'flex', top: 0, left: 0, width: '50vw', margin: '1rem' }}>
            <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
              <Button icon="setting" size="small" style={{ marginRight: '0.5rem' }} />
            </Dropdown>
            <InvitesButton />
          </div>
          <div style={{ position: 'fixed', display: 'flex', top: 0, right: 0, width: '50vw', justifyContent: 'flex-end', margin: '1rem' }}>
            {/* <Button size="small" style={{ marginRight: '0.5rem' }} value="save" onClick={() => this.updateArticleHtml(this.state.articleId, mediumDraftExporter(this.state.content))}>Save</Button> */}
            <Button size="small" value="edit" href="/write">
              <Icon type="plus"></Icon>
              {' '}
              Write
            </Button>
          </div>
        </div>
      }

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <p style={{ textAlign: 'center', fontFamily: 'Open Sans, sans-serif', fontSize: '12px', fontWeight: 600, color: 'rgba(0, 0, 0, 0.3)' }}>{profile.publicId.toUpperCase()}</p>
        <ul className="TitleText" style={{ textAlign: 'center', listStyle: 'none', padding: 0, margin: 0 }}>
          {
            visibleArticles.length === 0 && isCurrentUserAuthor
            ? <Text type="secondary">You haven't written anything yet.</Text>
            : visibleArticles.length === 0
            ? <Text type="secondary">They haven't published anything yet.</Text>
            : null
          }
          {
            visibleArticles.map((a, index) =>
              <li key={index} style={{ margin: '0.25rem 0' }}>
                <a href={`/@${a.authorPublicId}/${a.url}`} style={{ paddingLeft: a.status === 'DRAFT' ? '2.75rem' : 0 }}>
                  {a.title}
                </a>
                {
                  a.status === 'DRAFT'
                  && <Text type="secondary" style={{ fontWeight: 500, fontFamily: '"Open Sans", sans-serif', fontSize: '12px', marginLeft: '0.5rem', }}>(Draft)</Text> // <Tag style={{ position: 'relative', top: '-1px', marginLeft: '0.5rem', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Draft</Tag>
                }
              </li>)
          }
        </ul>
      </div>
    </div>
  );
}
// make this so it loads the profile, finds the write one
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firestore.data.users ? (() => {
      const empty = isEmpty(state.firestore.data.users);
      if (empty) {
        return {
          isLoaded: true,
          isEmpty: empty,
        };
      } else if (Object.keys(state.firestore.data.users).length > 1) {
        console.error('More than one profile found.')
        return {
          isLoaded: true,
          isEmpty: true,
        };
      } else {
        const profile = first(values(state.firestore.data.users));
        const id = first(Object.keys(state.firestore.data.users));
        return {
          isLoaded: true,
          isEmpty: empty,
          ...profile,
          id
        };
      }
    })() : {
      isLoaded: false,
      isEmpty: true,
    },
    articles: state.firestore.data.hasOwnProperty('articles') ? {
      isLoaded: true,
      isEmpty: isEmpty(state.firestore.data.articles),
      all: values(state.firestore.data.articles)
    } : {
      isLoaded: false,
      isEmpty: true,
    }
  };
};

const mapDispatchToProps = {
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(({ match }) => {
    return [
      {
        collection: 'users',
        where: [
          ['publicId', '==', match.params.id]
        ]
      },
      {
        collection: 'articles',
        where: [
          ['authorPublicId', '==', match.params.id]
        ]
      }
    ]
  }),
)(withRouter(Profile));
