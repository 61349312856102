// Main component of our application.
// We setup react-helmet, which let us nicely manage our <head />
// It's a nice library you should use!

import '../node_modules/medium-draft/lib/index.css';
import './AntDesign.less';
import './App.scss';

import { Redirect, Route, Switch } from 'react-router-dom';

import { BackTop } from 'antd';
import Landing from './website/Landing';
import Loader from './shared/components/Loader.jsx';
import Logout from './webapp/Logout';
import Profile from './webapp/Profile';
import React from 'react';
import Read from './webapp/Read';
import Welcome from './webapp/Welcome';
import Write from './webapp/Write';
import { compose } from 'redux';
// import importedComponent from 'react-imported-component';
// const HelloWorld2 = importedComponent(() => import('./scenes/code-splitting/HelloWorld2'));
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

const App = ({ profile, auth, history, match, location  }) => {
  // const PrivateRoute = ({ component: Component, ...rest }) => (
  //   <Route {...rest} render={(props) => (
  //     auth.isEmpty
  //       ? <Redirect to='/' />
  //       : <Component {...props} />
  //   )} />
  // );

  // TODO: Only have one loader that is managed by redux actions, so there's no flicker. Fine for now though.
  const { publicId, isLoaded } = profile;
  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div>
      <BackTop />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/invite/:code" component={Landing} />

        <Route exact path="/logout" component={Logout} />

        {/* title.cx/welcome */}
        <Route exact path="/welcome" component={Welcome} />

        <Route exact path="/@:authorPublicId/:articleUrl" component={Read} />

        {/* title.cx/write & title.cx/edit/:articleId */}
        {/* These don't use PrivateRoute because it causes jank in the Writing focus/UI. */}
        <Route exact path="/write" component={Write} />
        <Route exact path="/edit/:articleId" component={Write} />

        {/* title.cx/@:id */}
        <Route exact path="/profile" component={publicId ? () => <Redirect to={`/@${publicId}`} /> : () => <Loader />} />
        <Route exact path="/@:id" component={Profile} />

        {/* title.cx/@:id/:title - use npm install draft-convert to render */}
        {/* title.cx/settings */}
        {/* <Route exact path="/codeSplit" component={HelloWorld2} /> */}
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = {
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(withRouter(App));