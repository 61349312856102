import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
  background-color: #222222;
  padding: 2rem 1rem;
  text-align: center;
  @media (min-width: 525px) {

    padding: 4rem 2rem;
  }
`;

const QuoteText = styled.p`
  font-family: 'Frank Ruhl Libre', serif;
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0;
`;

const Attribution = styled.p`
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.5);
`;

export default function HomeTestimonial() {
  return (
    <Container>
      <QuoteText>“I honestly didn’t know I needed this.”</QuoteText>
      <Attribution>@FDF8QA</Attribution>
    </Container>
  )
}
