import './index.less';

import ExternalSitewideFooter from '../components/ExternalSitewideFooter';
import LandingBenefits from './components/LandingBenefits';
import LandingFeatures from './components/LandingFeatures';
import LandingFooterCTA from './components/LandingFooterCTA';
import LandingHero from './components/LandingHero';
import LandingTestimonial from './components/LandingTestimonial';
import React from 'react';

// const propTypes = {
//   match: PropTypes.any,
//   firestore: PropTypes.any,
//   isValidInviteCode: PropTypes.bool,
//   history: PropTypes.any,
//   profile: PropTypes.any,
// };

function Landing() {
  return (
    <div className="Landing">
      <LandingHero />
      <LandingBenefits />
      <LandingFeatures />
      <LandingTestimonial />
      <LandingFooterCTA />
      <ExternalSitewideFooter />
    </div>
  );
}

export default Landing;