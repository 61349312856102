import './Write.scss';

import { Button, Empty, Icon, Modal, Typography, message, notification } from 'antd';
import React, { Component } from 'react';
import { compose, lifecycle, withHandlers } from 'recompose';

import Editor from '../../shared/components/editor/Editor';
import Loader from '../../shared/components/Loader';
import PropTypes from 'prop-types';
import UrlSafeStringGenerator from 'url-safe-string';
import autosave from 'auto-save';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import { firestoreConnect } from 'react-redux-firebase';
import htmlToPlainText from 'html2plaintext';
import htmlToText from 'html-to-text';
import mediumDraftExporter from 'medium-draft/lib/exporter';
import uid from 'uid-safe';
import { withRouter } from 'react-router-dom';

const propTypes = {
  createArticle: PropTypes.func.isRequired,
  updateArticleHtml: PropTypes.func.isRequired,
  updateArticleStatus: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  firebase: PropTypes.shape({
    auth: PropTypes.func.isRequired,
  }).isRequired,
  profile: PropTypes.shape({
    publicId: PropTypes.string.isRequired,
  }).isRequired,
  firestore: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
};

class Write extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articleId: props.match.params.articleId || null,
      articleUrl: null,
      isLoadingArticle: false,
      isSaving: false,
      persistedArticleDocument: null,
      hasInitiatedCreateArticle: false,
      hasEditedContent: false,
      shouldUpdateArticleUrl: false,
    };

    this.urlGenerator = new UrlSafeStringGenerator({ maxLen: 40 });
    this.autoSave = autosave(500);
    const { Timestamp } = props.firestore;

    this.handleDelete = () => {
      const {
        Text
      } = Typography;
      const { articleId } = this.state;
      return this.props.deleteArticle(articleId)
        .then(() => {
          this.props.history.push('/profile');
        }).catch(err => {
          console.error(err);
          notification.error({
            message: `There was a problem deleting the article.`,
            description:
              <span>
                <Text>Please refresh the page and try again. If you continue experiencing issues, contact support from your profile.</Text>
                <Text code ellipsis style={{ hyphens: 'none', wordBreak: 'break-all', whiteSpace: 'normal' }}>{err.message}</Text>
              </span>,
            duration: 0,
          });
        });
    }

    this.handleAutoSave = (newContent) => {
      const INIT_CONTENT = '<p class="md-block-unstyled"><br/></p>';
      const { articleId, hasEditedContent, hasInitiatedCreateArticle, persistedArticleDocument } = this.state;
      const { match } = this.props;
      const html = mediumDraftExporter(newContent);

      if (!hasEditedContent && mediumDraftExporter(newContent) !== INIT_CONTENT) {
        // Check if the content has been edited and update state if so
        this.setState({ hasEditedContent: true });
      }
      if (persistedArticleDocument && !persistedArticleDocument.data().url) {
        this.setState({ shouldUpdateArticleUrl: true });
      }
      if (articleId) {
        // Trigger auto-save if there is an articleId
        this.setState({ isSaving: true });
        this.autoSave(() => {
          this.props.updateArticleHtml(articleId, html, Timestamp.now())
            .then(() => this.setState({ isSaving: false }));
        });
      } else if (
        hasEditedContent
        && html.length > 50
        && !hasInitiatedCreateArticle
        && !articleId) {
        // Create new article if it hasn't already been done and the content has been edited + is over 100 char length
        // Generate url
        let url = this.urlGenerator.generate(htmlToText.fromString(html, { wordwrap: false }));
        const lastDashIndex = url.lastIndexOf('-');
        url = lastDashIndex > 0
          ? url.substring(0, lastDashIndex).concat('-', uid.sync(4))
          : url.substring(0, 25).concat('-', uid.sync(4));
        // Generate title
        let title = htmlToPlainText(html).substr(0, 75);
        const lastSpaceIndex = title.lastIndexOf(' ');
        title = lastSpaceIndex > 0
          ? title.substr(0, lastSpaceIndex)
          : title.substr(0, 25);
        // Handle update
        this.setState({ hasInitiatedCreateArticle: true },
          () => this.props.createArticle({
              html,
              status: 'DRAFT',
              authorId: this.props.auth.uid,
              authorPublicId: this.props.profile.publicId,
              title,
              url,
              createdAt: Timestamp.now(),
              lastEditedAt: Timestamp.now(),
          }).then(doc => this.setState({ articleId: doc.id, articleUrl: url })));
      }

      if (match.path === '/write' && articleId) {
        // Update url to /edit/:articleId once created
        this.props.history.push(`/edit/${articleId}`);
      }
      this.setState({ content: newContent });
    };

    this.handlePublish = () => {
      const { articleId, articleUrl } = this.state;
      const { confirm } = Modal;
      const { publicId } = props.profile;
      const { Text } = Typography;
      confirm({
        title: 'Make this post public?',
        content: `It'll be visible by anyone on the web. You'll still be able to edit it.`,
        okText: "Publish",
        onOk() {
          return props.updateArticleStatus(articleId, 'PUBLIC', Timestamp.now()).then(res => {
            notification['success']({
              message: `It's live!`,
              description:
                <span>
                  <p>Your writing is now publicly visible at:</p>
                  <Text code ellipsis style={{ hyphens: 'none', wordBreak: 'break-all', whiteSpace: 'normal' }}>title.cx/@{publicId}/{articleUrl}</Text>
                  <Button block style={{ margin: '0.5rem 0'}} href={`/@${publicId}/${articleUrl}`}>View Live</Button>
                  <Button block onClick={() => {
                    copy(`https://title.cx/@${publicId}/${articleUrl}`);
                    message.success('Copied to Clipboard');
                    }}>Copy Link</Button>
                </span>,
              duration: 0,
            });
          });
        },
        onCancel() {},
      });
    }
  }

  componentDidMount() {
    const { match: { path }} = this.props;
    const { articleId } = this.state;
    if (path === '/edit/:articleId') {
      this.setState({ isLoadingArticle: true });
      this.props.firestore.collection('articles').doc(articleId).get()
        .then(doc => {
          this.setState({
            persistedArticleDocument: doc,
            articleUrl: doc.data().url,
            isLoadingArticle: false,
          });
        }).catch(err => {
          notification['error']({
            message: `Sorry, we couldn't load this article.`,
            description:
              <span>Please check your internet connection, try again, and <a href="mailto:ciao.title@gmail.com">contact us</a> if you continue having any issues.</span>,
            duration: 0,
          });
        });
    }
  }

  render() {
    const { Text } = Typography;
    const { articleId, articleUrl, isLoadingArticle, isSaving, persistedArticleDocument } = this.state;
    const { profile: { publicId }, auth } = this.props;

    if (isLoadingArticle) {
      return <Loader />;
    }

    if (auth.isEmpty) {
      // User is not logged in
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Icon type="lock" style={{ fontSize: '1rem', marginBottom: '0.5rem' }} />
          <Text>
            You must be logged in to write.
          </Text>
        </div>
      );
    }

    if (persistedArticleDocument && auth.uid !== persistedArticleDocument.data().authorId) {
      // Current user is not author
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Icon type="lock" style={{ fontSize: '1rem', marginBottom: '0.5rem' }} />
          <Text>
            You do not have edit access.
          </Text>
        </div>
      );
    }

    if (persistedArticleDocument && !persistedArticleDocument.exists) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          {/* <Icon type="close" style={{ marginBottom: '1rem', fontSize: '2rem', opacity: 0.45 }} /> */}
          <span style={{ fontSize: '2rem', marginBottom: '0.5rem', color: 'black', opacity: 1 }}>👀</span>
          <p style={{ maxWidth: '350px', textAlign: 'center' }}>There's no item of yours here. Try again from your profile. <a href="mailto:ciao.title@gmail.com">Contact us</a> if you keep having trouble.</p>
          <div>
            <Button href="/profile" size="small" style={{ marginRight: '0.5rem' }}>Go to Profile</Button>
            <Button href="/write" size="small">Write Something</Button>
          </div>
        </div>
      );
    }

    const alreadyPublished = persistedArticleDocument && persistedArticleDocument.data().status === 'PUBLIC';
    return (
      <div className="Write">
        <div
          className="Actions"
          style={{ position: 'fixed', display: 'flex', top: 0, left: 0, width: '50vw', margin: '1rem' }}>
            <Button icon="user" size="small" value="profile" href={`/@${publicId}`} />
        </div>
        <div
          className="Actions"
          style={{ position: 'fixed', display: 'flex', top: 0, right: 0, width: '50vw', justifyContent: 'flex-end', margin: '1rem' }}>
            { isSaving && <Text style={{ position: 'relative', top: '3px', fontSize: '12px', marginRight: '0.5rem', color: 'rgba(0, 0, 0, 0.35' }}>Saving...</Text> }

            {/* <Button  style={{ marginRight: '0.5rem' }} size="small" value="delete" onClick={this.handleDelete}>
              <Icon type="delete" />
            </Button> */}

            { alreadyPublished && <Button style={{ marginRight: '0.5rem' }} size="small" value="view-live" href={`/@${this.props.profile.publicId}/${articleUrl}`}>View Live</Button> }

            <Button size="small" value="publish" onClick={this.handlePublish} disabled={!articleId || alreadyPublished}>
              { alreadyPublished ? 'Published' : 'Publish' }
            </Button>
        </div>
        <Editor onEditorChange={this.handleAutoSave} htmlToPreload={persistedArticleDocument && persistedArticleDocument.data().html} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = {
};

Write.propTypes = propTypes;

export default compose(
  firestoreConnect(),
  withHandlers({
    createArticle: props => ({ html, status, authorId, authorPublicId, title, url }) => props.firestore.collection('articles').add({ html, status, likes: 0, authorId, authorPublicId, title, url }),
    updateArticleHtml: props => (articleId, html, lastEditedAt) => props.firestore.collection('articles').doc(articleId).update({ html, lastEditedAt }),
    updateArticleUrl: props => (articleId, url) => props.firestore.collection('articles').doc(articleId).update({ url }),
    updateArticleTitle: props => (articleId, title) => props.firestore.collection('articles').doc(articleId).update({ title }),
    updateArticleStatus: props => (articleId, status, publishedAt) => props.firestore.collection('articles').doc(articleId).update({ status, publishedAt }),
    deleteArticle: props => (articleId) => props.firestore.collection('articles').doc(articleId).delete(),
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Write));