import './Editor.scss';

import { Editor, createEditorState } from 'medium-draft';
import React, { Component } from 'react';

import UIfx from 'uifx';
import { convertToRaw } from 'draft-js';
import fxDefaultKey from './assets/sound-fx/fx-defaultKey.mp3';
import fxSpaceBar from './assets/sound-fx/fx-spaceBar.mp3';
import mediumDraftExporter from 'medium-draft/lib/exporter';
import mediumDraftImporter from 'medium-draft/lib/importer';

const fx = {
  defaultKey: new UIfx(fxDefaultKey, { volume: 0.2 }),
  spaceBar: new UIfx(fxSpaceBar, { volume: 0.1 }),
};

const blockButtons = [{
  label: 'H1',
  style: 'header-one',
  icon: 'header',
  description: 'Heading 1',
},
{
  label: 'H2',
  style: 'header-two',
  icon: 'header',
  description: 'Heading 2',
}];

const toolbarConfig = {
  block: ['header-one', 'header-two'],
  inline: ['BOLD', 'ITALIC', 'UNDERLINE'],
};

export class EnhancedEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: props.htmlToPreload ? createEditorState(convertToRaw(mediumDraftImporter(props.htmlToPreload))) : createEditorState(),
    };

    this.onChange = (editorState) => {
      const oldHtml = mediumDraftExporter(this.state.editorState.getCurrentContent());
      const newHtml = mediumDraftExporter(editorState.getCurrentContent());
      if (oldHtml !== newHtml) {
        props.onEditorChange(editorState.getCurrentContent());
      }
      this.setState({ editorState });
    };

    this.refsEditor = React.createRef();
  }

  componentDidMount() {
    // Focus editor
    this.refsEditor.current.focus();

    // Add event listeners
    this.onKeyDown = document.addEventListener('keydown', ({
      keyCode,
      repeat,
    }) => {
      if (repeat) { return; }
      switch(true) {
        case keyCode === 16: // shift
          break;
        case keyCode === 17: // control
          break;
        case keyCode === 18: // alt
          break;
        case keyCode === 93: // left meta
          break;
        case keyCode === 91: // right meta
          break;
        case keyCode === 32: // spacebar
          fx.spaceBar.play();
          break;
        default:
          fx.defaultKey.play();
          break;
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  render() {
    const { editorState } = this.state;
    return (
      <div
        className="Editor TitleText"
        style={{ maxWidth: '725px', margin: '4rem auto' }}>
        <Editor
          ref={this.refsEditor}
          editorState={editorState}
          sideButtons={[]}
          toolbarConfig={toolbarConfig}
          blockButtons={blockButtons}
          placeholder="Try writing whatever comes to mind"
          onChange={this.onChange} />
      </div>
    );
  }
}

export default EnhancedEditor;