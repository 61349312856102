import React from 'react'
import SignUpForm from '../../components/SignUpForm';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem 1rem;
  max-width: 450px;
  margin: 0 auto;
`;

const HeaderText = styled.h3`
  line-height: 120%;
`;

const CaptionText = styled.p`
  font-size: .75rem;
  margin-top: 0;
  padding-top: 0;
  color: #999999;
  text-align: center;
  @media (min-width: 525px)
`;

const FormWrapper = styled.div`
  max-width: 300px;
  margin: 0 auto;
`;

export default function HomeFooterCTA() {
  return (
    <Container>
      <HeaderText>Try it free during Covid-19</HeaderText>
      <CaptionText>
        We've opened up Title to everyone during this time.
        <br/>
        If you you like it, <a style={{ color: '#999999', textDecoration: 'underline' }} href="mailto:ciao@title.cx?subject=[Title] I liked it!&body=The next feature we should add is...">let us know!</a></CaptionText>
      <FormWrapper>
        <SignUpForm layout="vertical" />
      </FormWrapper>
    </Container>
  )
}
