import React from 'react'
import { Typography } from 'antd';
import key1 from '../assets/key-1.png';
import key2 from '../assets/key-2.png';
import key3 from '../assets/key-3.png';
import styled from 'styled-components';

const { Paragraph } = Typography;

const Container = styled.section`
  padding: 2rem 1rem;
  background-color: #F2F2F2;
  @media (min-width: 525px) {
    padding-top: 3rem;
  }
`;

const HiddenNumber = styled(Paragraph)`
  display: none;
`;

const KeyList = styled.ul`
  list-style: none;
  padding: 0;
  @media (min-width: 525px) {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    > li {
      width: 33.33%;
      margin: 2rem;
    }
  }
`;

const KeyListItem = styled.li`
  margin-top: 2rem;
`;

const Key = styled.img`
 height: 54px;
 width: 54px;
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 0.75rem;
  font-family: 'Frank Ruhl Libre', serif;
  font-size: 1rem;
`;

export default function HomeBenefits() {
  return (
    <Container>
      <h2>Why try Title?</h2>
      <KeyList>
        <KeyListItem>
          <HiddenNumber>#1  </HiddenNumber>
          <Key src={key1} alt="A key with 1 and !" />
          <StyledParagraph color="textPrimary">Because you love to write!</StyledParagraph>
        </KeyListItem>
        <KeyListItem>
          <HiddenNumber>#2</HiddenNumber>
          <Key src={key2} alt="A key with 2 and @" />
          <StyledParagraph color="textPrimary">Because you deserve nice things, and we are working hard to make Title really really nice.</StyledParagraph>
        </KeyListItem>
        <KeyListItem>
          <HiddenNumber>#3</HiddenNumber>
          <Key src={key3} alt="A key with 3 and #" />
          <StyledParagraph color="textPrimary">You want to share your writing with others, but don't know if you want to publish it publicly.</StyledParagraph>
        </KeyListItem>
      </KeyList>
    </Container>
  )
}
