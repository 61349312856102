import { Button, Typography, message, notification } from 'antd';
import React, { Component } from 'react';
import { compose, lifecycle, withHandlers } from 'recompose';

import Loader from '../../shared/components/Loader';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

const propTypes = {
  persistedArticleDocument: PropTypes.shape({
    html: PropTypes.string,
    authorId: PropTypes.string,
    authorPublicId: PropTypes.string,
    url: PropTypes.string,
    status: PropTypes.string,
  }),
  firestore: PropTypes.shape({
    Timestamp: PropTypes.any,
  }),
  updateArticleStatus: PropTypes.func.isRequired,
  isLoadingArticle: PropTypes.bool.isRequired,
  match: PropTypes.any,
  auth: PropTypes.any,
};

class Read extends Component {
  constructor(props) {
    super(props);
    this.handlePublish = () => {
      const {
        Text
      } = Typography;

      const { persistedArticleDocument } = this.props;
      const articleId = persistedArticleDocument.id;
      const { url, authorPublicId } = persistedArticleDocument.data();
      const { Timestamp } = props.firestore;
      return this.props.updateArticleStatus(articleId, 'PUBLIC', Timestamp.now()).then(() => {
        notification.success({
          message: `It's live!`,
          description:
            <span>
              <p>Your writing is now publicly visible at:</p>
              <Text code ellipsis style={{ hyphens: 'none', wordBreak: 'break-all', whiteSpace: 'normal' }}>title.cx/@{authorPublicId}/{url}</Text>
              <Button block style={{ margin: '0.5rem 0'}} href={`/@${authorPublicId}/${url}`}>View Live</Button>
              <Button block onClick={() => {
                copy(`https://title.cx/@${authorPublicId}/${url}`);
                message.success('Copied to Clipboard');
                }}>Copy Link</Button>
            </span>,
          duration: 0,
        });
      });
    }
  }

  render () {
    const { auth, persistedArticleDocument, isLoadingArticle, match: { params: { authorPublicId }} } = this.props;
    if (isLoadingArticle) {
      return <Loader />;
    }
    if (persistedArticleDocument && !persistedArticleDocument.exists) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <span style={{ fontSize: '2rem', marginBottom: '0.5rem', color: 'black', opacity: 1 }}>👀</span>
          <p style={{ maxWidth: '350px', textAlign: 'center' }}>There{"'"}s no article here. Double check the link and <a href="mailto:ciao.title@gmail.com">contact us</a> if you think it's a bug.</p>
          {/* <div>
            <Button href="/profile" size="small" style={{ marginRight: '0.5rem' }}>Go to Profile</Button>
            <Button href="/write" size="small">Write Something</Button>
          </div> */}
        </div>
      );
    }
    const { html, authorId, status } = persistedArticleDocument.data();
    const isCurrentUserAuthor = auth && auth.uid == authorId;
    return (
      <div>
        {
          auth && auth.uid &&
          <div
            style={{ position: 'fixed', display: 'flex', top: 0, left: 0, width: '50vw', margin: '1rem' }}>
              <Button size="small" value="profile" href="/profile" icon="user" />
          </div>
        }
        {
          isCurrentUserAuthor &&
          <div
            style={{ position: 'fixed', display: 'flex', top: 0, right: 0, width: '50vw', justifyContent: 'flex-end', margin: '1rem' }}>
              {/* <Button size="small" style={{ marginRight: '0.5rem' }} value="save" onClick={() => this.updateArticleHtml(this.state.articleId, mediumDraftExporter(this.state.content))}>Save</Button> */}
  
              <Button style={{ marginRight: '0.5rem' }} size="small" value="edit" href={`/edit/${persistedArticleDocument.id}`}>
                Edit
                {/* <Tooltip title="Make this draft publicly viewable." placement="topRight" overlayStyle={{ fontSize: '12px' }}>
                  <Icon type="question-circle" style={{ fontSize: '.75rem', opacity: 0.65 }} />
                </Tooltip> */}
              </Button>
              <Button size="small" type="primary" value="publish" onClick={this.handlePublish} disabled={status === 'PUBLIC'}>
                { status === 'PUBLIC' ? 'Published' : 'Publish' }
              </Button>
          </div>
        }
  
        <div className="TitleText" style={{ maxWidth: '725px', margin: '4rem auto', padding: '0 1rem' }}>
          <a href={`/@${authorPublicId}`} style={{ fontFamily: 'Open Sans, sans-serif', fontWeight: 600, fontSize: '0.8rem', color: 'rgba(0, 0, 0, 0.3)' }}>{authorPublicId.toUpperCase()}</a>
          { ReactHtmlParser(html) }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    // profile: state.firebase.profile
  };
};

const mapDispatchToProps = {
};

Read.propTypes = propTypes;

export default compose(
  firestoreConnect(),
  withHandlers({
    updateArticleStatus: props => (articleId, status, publishedAt) => props.firestore.collection('articles').doc(articleId).update({ status, publishedAt }),
    getArticle: props => (authorPublicId, articleUrl) => props.firestore.collection('articles')
      .where('authorPublicId', '==', authorPublicId)
      .where('url', '==', articleUrl).get(),
  }),
  lifecycle({
    componentWillMount() {
      const { match: { params: { authorPublicId, articleUrl }}} = this.props;
      this.setState({ isLoadingArticle: true });
      this.props.getArticle(authorPublicId, articleUrl).then(qs => qs.docs.length > 0 ? this.setState({ persistedArticleDocument: qs.docs[0], isLoadingArticle: false }) : null)
    }
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Read));