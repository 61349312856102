import { Button, Typography } from 'antd';

import Loader from '../../shared/components/Loader';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

const Welcome = ({ profile, history }) => {
  const {
    Paragraph
  } = Typography;

  if (!profile.isLoaded || profile.isEmpty || !profile.publicId) {
    return (
      <Loader />
      );
    }

  return (
    <div className="Welcome" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div className="TitleText" style={{ maxWidth: '600px', margin: '4rem auto 0', padding: '0 1rem' }} >
        <Paragraph>{profile.publicId.toUpperCase()} –</Paragraph>
        <Paragraph>
          Welcome to a community of people who like to write plainly and privately. Welcome to Title.
        </Paragraph>
        <Paragraph>
          One of our core beliefs is that each member of the Title community is a business partner. Title provides the platform, but our collective desire to write, express, and form words creates something bigger.
        </Paragraph>
        <Paragraph>
          I started this in search of space for raw thinking. I craved a beautiful and simple tool that empowered me to write more, better. I wanted something I truly looked forward to using.
        </Paragraph>
        <Paragraph>
          On Title, we are pseudo-anonymous, known only by our 6-character strings of letters and numbers. I hope you’ll find the same freedom to write and express yourself that I did.
        </Paragraph>
        <Paragraph>
          _Title
        </Paragraph>
      </div>
      <Button size="small" onClick={() => history.push('/write')}>Start</Button>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = {
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
  )(withRouter(Welcome));