import { AsYouTypeFormatter, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { Button, Form, Icon, Input, Tooltip, Typography, message } from 'antd';
import React, { Component } from 'react';
import { firebaseConnect, firestoreConnect } from 'react-redux-firebase';
import { lowerCase, startCase } from 'lodash';

import { compose } from 'redux';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom';

const VIEWS = {
  'ENTER_PHONE': 'ENTER_PHONE',
  'CONFIRM_CODE': 'CONFIRM_CODE',
};
const { Paragraph, Text } = Typography;

const Container = styled.div`
  margin: 0;
`;

const FormWrapper = styled.div`
  ${props => props.layout === 'horizontal' && `
    @media (min-width: 525px) {
      display: flex;
      align-items: center;
      > form {
        display: flex;
        align-items: center;
        > * {
          margin-right: 0.5rem;
        }
      }
    }
  `}
`;

const StyledFormItem = styled(Form.Item)`
  padding: 0;
  margin: 0;
`

const StyledButton = styled(Button)``

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentView: VIEWS.ENTER_PHONE,
      phoneValue: '',
    }
    this.phoneInputRef = null;
    this.codeInputRef = null;
    this.AsYouTypeFormatter = new AsYouTypeFormatter('US');

    this.onPhoneSubmit = () => {
      const phoneNumber = parsePhoneNumberFromString(this.state.phoneValue, 'US');
      const value = phoneNumber.number;
      this.setState({ isLoadingPhone: true });
      firebase.signInWithPhoneNumber(value, this.recaptchaVerifier)
        .then(confirmationResult => {
          this.confirmationResult = confirmationResult;
          this.setState({ currentView: VIEWS.CONFIRM_CODE });
        })
        .catch(err => {
          message.error(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Text>Hmm, please try again.</Text>
              <Text code style={{ position: 'relative', left: '-4px' }}>Error: {startCase(lowerCase(err.message))}</Text>
            </div>
            , 10);
        })
        .then(() => this.setState({ isLoadingPhone: false }));
    };

    this.onCodeSubmit = () => {
      this.setState({ isLoadingCode: true });
      const value = this.codeInputRef.input.value;
      this.confirmationResult.confirm(value)
        .then((result) => {
          // Successful login
          this.setState({ isLoadingCode: false });
          const { isNewUser } = result.additionalUserInfo;
          if (isNewUser) {
            this.props.history.push('/welcome');
          } else {
            this.props.history.push('/profile');
          }
        })
        .catch(err => {
          this.setState({ isLoadingCode: false });
          message.error(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Text>Hmm, please try again.</Text>
              <Text code style={{ position: 'relative', left: '-4px' }}>Error: {startCase(lowerCase(err.message))}</Text>
            </div>
            , 10);
        });
    }

    this.handlePhoneKeyUp = (ev) => {
      const BACKSPACE = 8;
      const { phoneValue } = this.state;
      const {keyCode} = ev;
      if (keyCode === BACKSPACE && phoneValue.length > 0) {
        this.AsYouTypeFormatter.clear();
        const newValue = this.AsYouTypeFormatter.inputDigit(phoneValue.slice(0, -1));
        this.setState({ phoneValue: newValue });
      } else if (keyCode >= 48 && keyCode <= 57) {
        const newValue = this.AsYouTypeFormatter.inputDigit(ev.key);
        this.setState({ phoneValue: newValue })
      }
    }
  }


  componentDidMount() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('btn-submit-phone', {
      'size': 'invisible',
    });
  }

  render() {
    const { layout } = this.props;
    const {
      phoneValue,
      currentView,
      isLoadingCode,
      isLoadingPhone
    } = this.state;

    return (
      <Container>
          <FormWrapper layout={layout}>
            <Form layout={layout || 'vertical'}>
              <StyledFormItem>
                <Input
                  value={phoneValue}
                  disabled={currentView !== VIEWS.ENTER_PHONE}
                  placeholder="Enter phone number"
                  ref={ref => this.phoneInputRef = ref}
                  onKeyUp={this.handlePhoneKeyUp}
                  onPressEnter={this.onPhoneSubmit}
                  suffix={isLoadingPhone && <Icon type="loading" />} />
              </StyledFormItem>
              { currentView === VIEWS.ENTER_PHONE && <StyledButton style={{ width: layout === 'horizontal' ? 'unset' : '100%' }} id="btn-submit-phone" onClick={this.onPhoneSubmit}>Join Beta</StyledButton> }
            </ Form>

          { currentView === VIEWS.CONFIRM_CODE &&
            <Form layout={layout || 'vertical'}>
              <StyledFormItem>
                <Input
                  autoFocus
                  ref={ref => this.codeInputRef = ref}
                  placeholder="Enter code"
                  onPressEnter={this.onCodeSubmit}
                  suffix={<Icon type="loading" style={{ opacity: isLoadingCode ? 1 : 0 }} />} />
              </StyledFormItem>
              <StyledButton style={{ width: layout === 'horizontal' ? 'unset' : '100%' }} onClick={this.onCodeSubmit}>Verify</StyledButton>
            </ Form>
          }
        </FormWrapper>
        <Paragraph style={{ textAlign: layout === 'horizontal' ? 'left' : 'center', marginTop: '0.5rem', fontSize: '12px' }} type="secondary">By signing up, you acknowledge and agree to our <a style={{ color: '#999', textDecoration: 'underline' }} href="https://studioflurry.com/terms">Terms of Use</a> and <a style={{ color: '#999', textDecoration: 'underline' }} href="https://studioflurry.com/privacy">Privacy Policy</a>.</Paragraph>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    isValidInviteCode: !!state.firestore.data.inviteCodes,
  };
};

const mapDispatchToProps = {
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
  firestoreConnect(),
)(withRouter(SignUpForm));