import { Button, Tag, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'

import SignUpForm from '../../components/SignUpForm';
import { firestoreConnect } from 'react-redux-firebase';
import getViewport from '../../../shared/utilities/getViewport';
import heroBackgroundMobile from '../assets/hero-background-mobile.jpg';
import heroBackgroundTablet from '../assets/hero-background-tablet.jpg';
import logo from '../../../shared/assets/logo.gif';
import styled from 'styled-components';

const { Paragraph, Text } = Typography;
let { width, height } = getViewport();
const MOBILE_BREAKPOINT = 525;
let isMobile = width < MOBILE_BREAKPOINT;

const HeroContainer = styled.div`
  position: relative;
  background-image: url(${isMobile ? heroBackgroundMobile : heroBackgroundTablet});
  background-repeat: no-repeat;
  background-position: ${isMobile ? 'center' : '100% 0%'};
  background-size: ${isMobile ? 'cover' : '50% 100%'};
  height: ${height}px;
  @media (min-width: 525px) {
    max-height: 500px;
  }
`;

const HeaderContainer = styled.div`
  height: 72px;
  display: flex;
  align-content: space-between;
  align-items: center;
  padding: 0 1rem;
`;

const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  text-shadow: 0px 1px 4px white, 0px -1px 4px white;
`;

const Logo = styled.img`
  max-height: 20px;
  mix-blend-mode: darken;
`;

const HeroDisplayText = styled.h1`
  font-size: 2.25rem;
  line-height: 120%;
  padding: 0 0.5rem;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  text-shadow: 0px 2px 4px white;
  @media (min-width: 525px) {
    font-size: 2.75rem;
    text-align: left;
    margin-top: 1.25rem;
    margin-left: unset;
    margin-right: unset;
    max-width: 50%;
    padding: 0 3rem 0 6rem;
  }
  @media (min-width: 525px) and (max-width: 786px) {
    font-size: 2rem;
    padding: 0 2rem;
  }
  @media (min-width: 1440px) {
    padding-top: 2.5rem;
  }
`;

const HeroSubtitleText = styled(Paragraph)`
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Frank Ruhl Libre', serif;
  letter-spacing: 0;
  text-align: center;
  max-width: 450px;
  margin: 0.5rem 1rem 0;
  padding: 0 0.5rem;
  text-shadow: 0px 0px 6px white;
  @media (min-width: 525px) {
    text-align: left;
    margin-left: unset;
    margin-right: unset;
    max-width: 50%;
    padding: 0 6rem;
  }
  @media (min-width: 525px) and (max-width: 786px) {
    font-size: 1rem;
    padding: 0 2rem;
    margin-top: 0.5rem;
  }
`;

const FormWrapper = styled.div`
  @media (max-width: 525px) {
    margin: 0 1rem;
  }
  @media (min-width: 525px) {
    width: 50%;
    padding: 0 6rem;
    position: relative;
    > form {
      position: absolute;
      left: 6rem;
      margin: 0;
    }
  }
  @media (min-width: 525px) and (max-width: 786px) {
    padding: 0 2rem;
  }
`;

const HeroFooter = styled.div`
  position: absolute;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media(min-width: 525px) {
    justify-content: flex-start;
  }
  @media (min-width: 525px) and (max-width: 786px) {
    padding: 1rem 2rem;
  }
`;

const getActiveCountEstimate = () => {
  const date = new Date();
  var hours = date.getHours();
  // var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;

  let base = 0;
  switch(ampm) {
    case 'am':
      if (0 <= hours && hours < 2) {
        base = 60;
      } else if (2 <= hours && hours < 7) {
        base = 30;
      } else if (7 <= hours && hours < 10) {
        base = 100;
      } else {
        base = 120;
      }
      break;
    case 'pm':
      if (12 <= hours && hours < 1) {
        base = 140;
      } else if (1 <= hours && hours < 5) {
        base = 90;
      } else {
        base = 110;
      }
      break;
  }
  let variance = Math.random() * 10;
  return Math.ceil(base + variance);
};

function LandingHero({ profile }) {
  const [windowWidth, setWindowWidth ] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight ] = useState(window.innerHeight);

  const heroContainerEl = useRef(null);
  const handleWindowResize = () => {
    const { width: newWidth, height: newHeight } = getViewport();
    width = newWidth;
    height = newHeight;
    setWindowWidth(newWidth);
    setWindowHeight(newHeight);
    isMobile = newWidth < MOBILE_BREAKPOINT;
    heroContainerEl.current.style.backgroundImage = `url(${isMobile ? heroBackgroundMobile : heroBackgroundTablet})`;
    heroContainerEl.current.style.backgroundPosition = isMobile ? 'center' : '100% 0%';
    heroContainerEl.current.style.backgroundSize = isMobile ? 'cover' : '50% 100%';
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, [windowWidth, windowHeight])

    const renderActiveCount = () => {
      const {
        Text
      } = Typography;
      // TODO: Make this responsive friendly
      const activeCount = getActiveCountEstimate();
      return (
        <HeroFooter>
          <div className="Circle Blink" />
          <Text type="secondary">{activeCount} writers online</Text>
        </HeroFooter>
      )
    }

    return (
      <HeroContainer ref={heroContainerEl}>
        <HeaderContainer>
          <Logo src={logo} />
          <Tag style={{ marginLeft: '0.5rem' }} color="volcano">Beta</Tag>
          {
            profile && profile.publicId ?
            // Link to profile/dashboard
            <Button icon="user" size="small" value="profile" href={`/@${profile.publicId}`}>Dashboard</Button> :
            <HeaderActions>
              {/* <Text type="secondary">Login</Text> */}
            </HeaderActions>
          }
        </HeaderContainer>
        <HeroDisplayText>Beautiful anonymous writing</HeroDisplayText>
        <HeroSubtitleText>Introducing Title, a minimalist new text editor for anonymous online writing.</HeroSubtitleText>
        <FormWrapper>
          <SignUpForm layout={isMobile ? 'vertical' : 'horizontal'} />
        </FormWrapper>
        {renderActiveCount()}
      </HeroContainer>
    );
}

export default firestoreConnect()(LandingHero);