import './index.css';
import 'firebase/firestore'; // make sure you add this for firestore

import { BrowserRouter, Route } from 'react-router-dom';

import App from './App';
import GoogleAnalytics from 'react-ga';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { createStore } from 'redux';
import firebase from 'firebase';
import firebaseConfig from './firebaseConfig';
import rootReducer from './redux-firebase/reducers/rootReducer';

// import 'firebase/auth';
// import 'firebase/database';



const rrfConfig = {
  userProfile: 'users',
  enableLogging: true,
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState);
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

GoogleAnalytics.initialize('UA-147001304-1');
firebase.auth().onIdTokenChanged(function(user) {
  if (user) {
    GoogleAnalytics.set({ userId: user.uid });
  }
});

const app = (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/" render={({location}) => {
            GoogleAnalytics.pageview(location.pathname + location.search);
          return null;
        }} />
        <App />
      </BrowserRouter>
    </ReactReduxFirebaseProvider>
  </Provider>
);

ReactDOM.render(
  app,
  document.getElementById('root')
);
