import React from 'react'
import { Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

const Container = styled.section`
  padding: 2rem 1rem;
  @media (min-width: 525px) {
    max-width: 600px;
    margin: 0 auto;
  }
`;

const CaptionText = styled(Paragraph)`
  font-size: .75rem;
  margin: 0;
  padding: 0;
  color: #999999;
  text-align: center;
`;

const StyledList = styled.ol`
  list-style-position: inside;
  padding-inline-start: 0;
  margin-left: 0.5rem;
  border-left: 0.5px solid #EDEDED;
`;

const StyledListItem = styled.li`
  font-family: 'Frank Ruhl Libre', serif;
  font-size: 1rem;
  border-bottom: 0.5px solid #EDEDED;
  padding: 0.5rem 0.5rem 0.5rem 3rem;
  position: relative;
  left: -2rem;
  width: 115%;
  &:first-child {
    border-top: none;
    list-style: none;
    height: 3rem;
  }
  &:last-child {
    border-bottom: none;
    list-style: none;
  }
  > div {
    display: contents;
  }
`;

export default function HomeFeatures() {
  return (
    <Container>
      <h2 style={{ marginBottom: 0 }}>What's in the box?</h2>
      <CaptionText>(by box, we mean Title - there is no actual box)</CaptionText>
      <StyledList start="0">
        {[
          ``,
          `Clean, no-frills UI – focus on the writing, forget the rest`,
          `Write without worry, but share your stuff too and see what resonates`,
          `Plugins! To start, typewriter sound effects, with many more to come`,
          `Your unique, randomly-generated 6-character id`,
          `A customizeable editing experience to write the way you want`,
          `Automatic back-ups via Google Drive or Dropbox`,
          `Control over your data; delete and export it anytime`,
          ``,
        ].map((reason, i) => <StyledListItem key={i}><Paragraph>{reason}</Paragraph></StyledListItem>)}
      </StyledList>
    </Container>
  )
}
