const firebaseConfig = {
  apiKey: "AIzaSyDDquOYWQqzftKij8_2J_jviAMXkmEOIC0",
  authDomain: "title-cx.firebaseapp.com",
  databaseURL: "https://title-cx.firebaseio.com",
  projectId: "title-cx",
  storageBucket: "title-cx.appspot.com",
  messagingSenderId: "221886727690",
  appId: "1:221886727690:web:ea61b74f7c4c4cc3"
};

export default firebaseConfig;