import { Button, Input, Modal, Typography, message, notification } from 'antd';
import React, { Component } from 'react';
import { compose, lifecycle, withHandlers } from 'recompose';

import { PhoneNumberUtil } from 'google-libphonenumber';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import snailMagic from './assets/snail-magic.jpg';
import { withRouter } from 'react-router-dom';

const propTypes = {};

class InvitesButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoadingPhone: false,
      recipientPhoneNumber: ''
    };

    this.showModal = () => {
      this.setState({
        visible: true,
        isLoadingPhone: false,
        recipientPhoneNumber: '',
      });
    };

    this.handleOk = e => {
      const {
        Text
      } = Typography;
      this.setState({ isLoadingPhone: true });
      const { recipientPhoneNumber } = this.state;
      const phoneUtil = PhoneNumberUtil.getInstance();
      const isValidPhoneNumber = (() => {
        try {
          return phoneUtil.isValidNumber(phoneUtil.parse(recipientPhoneNumber));
        }
        catch(err) {
          return false;
        }
      })();
      if (isValidPhoneNumber) {
        // Phone number is valid
        const { auth, useInvite } = this.props;
        useInvite({ recipientPhoneNumber, senderId: auth.uid })
          .then(res => {
            this.setState({ isLoadingPhone: false, visible: false });
            notification['success']({
              message: 'Let them know!',
              description:
                <p>You've successfully invited {recipientPhoneNumber}. They can now claim their account at <a href="https://title.cx">title.cx</a>.</p>,
              duration: 0,
            });
          })
          .catch(err => {
            console.error(err);
            this.setState({ isLoadingPhone: false });
            message.error(
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Text>Hmm, please try again.</Text>
                <Text code style={{ position: 'relative', left: '-4px' }}>Error: {startCase(lowerCase(err.message))}</Text>
              </div>
              , 5);
          });
      } else {
        message.error(
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Text>Hmm, please try again.</Text>
            <Text code style={{ position: 'relative', left: '-4px' }}>Error: Invalid phone number</Text>
          </div>
          , 5);
        this.setState({ isLoadingPhone: false });
      }
    };

    this.handleCancel = e => {
      this.setState({
        visible: false,
      });
    };
  }


  render() {
    const {
      h1,
      Paragraph
    } = Typography;

    const {
      invitesLeft
    } = this.props;

    if (!invitesLeft) {
      return null;
    }

    return (
      <div>
        {/* <Button size="small" onClick={this.showModal}>
          Use Invite ({invitesLeft})
        </Button> */}
        <Modal
          style={{ maxWidth: '450px' }}
          bodyStyle={{ display: 'flex', flexDirection: 'column' }}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={`Use Invite (${invitesLeft})`}
        >
          <img src={snailMagic} style={{ alignSelf: 'center', maxWidth: '325px' }} />
          <h1>Invite a friend to Title</h1>
          <Paragraph>Title is an invite-only community. This helps ensure content is thoughtful and members are active + intentional. Please be mindful as you use your invites.</Paragraph>
          <Input
            style={{ margin: '0 0 0.75rem', }}
            placeholder="+13109876543"
            name="recipientPhoneNumber"
            value={this.state.recipientPhoneNumber}
            onChange={(e) => this.setState({ recipientPhoneNumber: e.target.value })}
            onPressEnter={this.handleOk} />
          <Paragraph type="secondary" style={{ fontSize: '12px' }}><strong>Note: </strong>We don't send anything to this number. It just gets white-listed. They won't know you've invited them unless you tell them.</Paragraph>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    invitesLeft: state.firebase.profile ? state.firebase.profile.invitesLeft : 0,
  };
};

const mapDispatchToProps = {
};

InvitesButton.propTypes = propTypes;

export default compose(
  firestoreConnect(),
  withHandlers({
    useInvite: props => ({ recipientPhoneNumber, senderId }) => props.firestore.collection('invites').add({ recipientPhoneNumber, senderId }),
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(InvitesButton));