import Loader from '../../shared/components/Loader';
import React from 'react';
import firebase from 'firebase';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

const Logout = ({ history }) => {
  firebase.logout();
  history.push('/');
  return (<Loader />);
};

export default firestoreConnect()(withRouter(Logout));