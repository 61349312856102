import React from 'react'
import { Typography } from 'antd';
import styled from 'styled-components';

const { Paragraph } = Typography;

const Container = styled.div`
  font-size: .75rem;
  background-color: #F2F2F2; // linear-gradient(top to bottom, #FFFFFF, #F2F2F2, #F2F2F2);
  padding: 2rem 1rem;
  display: flex;
  justify-content: space-between;
  @media (min-width: 525px) {
    > p, div {
      margin: 0;
      padding: 0;
    }
  }
  @media(max-width: 525px) {
    text-align: center;
    flex-direction: column;
    #copyright {
      order: 2;
    }
  }
  > p, div {
    > a {
      color: #999999;
      text-decoration: underline;
    }
  }
`;

export default function ExternalSitewideFooter() {
  return (
    <Container>
      <Paragraph><a href="mailto:team@title.cx">Press</a> · <a href="mailto:support@title.cx">Support</a> · <a href="mailto:ciao@title.cx">Get in Touch</a></Paragraph>
      <Paragraph id="copyright" type="secondary">&copy; 2020 Studio Flurry. All Rights Reserved.</Paragraph>
      <Paragraph><a href="https://studioflurry.com/terms">Terms of Use</a> · <a href="https://studioflurry.com/privacy">Privacy Policy</a></Paragraph>
    </Container>
  )
}
