import { Icon } from 'antd';
import React from 'react';

function Loader() {
  return (
    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Icon type="loading" style={{ fontSize: '2rem' }} />
    </div>
  );
}

export default Loader;